<template>
  <div class="gallery-image">
    <label class="form-control-label"> {{ label }} </label>
    <div class="card-img">
      <div
        v-for="(image, key) in gallery"
        :key="key"
        class="gallery-image-container mb-2"
      >
        <img :src="`${image}`" class="gallery-image" />
        <base-button
          type="button"
          class="gallery-image-remove-btn btn btn-sm btn-danger"
          @click="removeImage(key)"
        >
          <span>
            <i class="fal fa-trash-alt"></i>
          </span>
        </base-button>
      </div>

      <base-button
        type="button"
        class="btn btn-sm mr-2 mb-2 gallery-add-image-btn"
      >
        <label for="imageInputGallery">
          <i v-if="loading" class="fas fa-spinner fa-spin"></i>
          <i v-else class="far fa-plus"></i>
        </label>
        <input
          id="imageInputGallery"
          ref="imageInput"
          accept="image/*"
          type="file"
          style="display: none"
          multiple
          @input="onFileChange"
        />
      </base-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "gallery-selector",

  props: {
    defaultGallery: {
      type: Array,
      default: null,
      description: "Default gallery to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
  },

  setup() {},

  data() {
    return {
      gallery: this.defaultGallery,
      loading: false,
    };
  },

  methods: {
    async onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      for (const file of Array.from(files)) {
        await this.uploadImage(file);
      }
    },

    async uploadImage(file) {
      this.loading = true;
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          const image_url = await this.$store.getters["files/url"];
          this.gallery = [...this.gallery, image_url];
          this.$emit("galleryChanged", this.gallery);
          this.$refs.imageInput.value = null;
          this.loading = false;
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
          this.loading = false;
        }
      }
    },

    removeImage(key) {
      this.gallery = this.gallery.filter((_, idx) => key !== idx);
      this.$emit("galleryChanged", this.gallery);
    },
  },

  watch: {
    defaultGallery(defaultGallery) {
      this.gallery = defaultGallery;
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-image {
  .form-control-label {
    font-size: 18px;
    line-height: 24px;
    font-family: "Greycliff CF Bold", sans-serif;
  }
  .card-img {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 12px 0 5px 0;
    .gallery-image-container {
      width: 80px;
      height: 80px;
      display: flex;
      position: relative;
      margin: 0 16px 16px 0;
      border-radius: 20px;
      border: solid 1px #d6d6d6;
      &:hover {
        .gallery-image-remove-btn {
          opacity: 1;
          visibility: visible;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
      .gallery-image-remove-btn {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        padding: 0;
        transform: translate(-50%, -50%);
        background-color: rgb(255, 0, 0, 0.6);
        margin: 0 !important;
        border: none;
        border-radius: 20px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        i {
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .gallery-add-image-btn {
      width: 120px;
      height: 120px;
      margin: 0 !important;
      padding: 0;
      border-radius: 20px;
      background-color: #aec082;
      box-shadow: none;
      i {
        font-size: 30px;
        color: #fff;
      }
    }
    .gallery-add-image-btn label {
      width: 100%;
      cursor: pointer;
      margin-bottom: 0;
    }
  }
}
</style>
