<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!resellerService">
      <span class="loader"></span>
    </span>
    <div v-if="resellerService" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("COMMON.PRODUCT_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img
                  src="../../../../../../public/img/kw-home.svg"
                  alt="icon"
                />
                <span>{{
                  $t("RESELLER_SERVICES.RESELLER_SERVICES_LIST")
                }}</span>
              </li>
              <li>
                <span>{{ resellerService.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deleteResellerService"
            v-if="$currentUserCan($permissions.PERM_DELETE_RESELLER_SERVICES)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editResellerService"
            v-if="$currentUserCan($permissions.PERM_EDIT_RESELLER_SERVICES)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("COMMON.GENERAL_INFORMATIONS") }}
          </span>
          <reseller-service-view-global :resellerService="resellerService" />
        </tab-pane>

        <tab-pane title="gallery" id="2">
          <span slot="title">
            {{ $t("COMMON.GALLERY") }}
          </span>
          <reseller-service-view-gallery :resellerService="resellerService" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <resellerService-view-logs :resellerService="resellerService" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ResellerServiceViewGlobal from "../partials/ResellerServiceViewGlobal.vue";
import ResellerServiceViewLogs from "../partials/ResellerServiceViewLogs.vue";
import ResellerServiceViewGallery from "../partials/ResellerServiceViewGallery.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    ResellerServiceViewGlobal,
    ResellerServiceViewGallery,
    ResellerServiceViewLogs,
  },

  mixins: [requestErrorMixin],

  props: {
    resellerServiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      resellerService: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "resellerServices/get",
          this.resellerServiceId
        );
        this.resellerService =
          this.$store.getters["resellerServices/resellerService"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editResellerService() {
      this.$emit("onEditResellerService", this.resellerService);
    },

    deleteResellerService() {
      this.$emit("onDeleteResellerService", this.resellerService);
    },
  },
};
</script>
