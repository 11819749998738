<template>
  <div class="image-upload-wrapper">
    <!--<label class="form-control-label"> {{ label }} </label>-->
    <div class="image-upload">
      <div class="image-upload-picture">
        <div v-if="image" class="profile-image">
          <img :src="`${image}`" class="profile-image argon-image" />
        </div>
        <div v-else class="profile-image default">
          <img src="/img/kw-default-image.svg" class="argon-image" />
        </div>
      </div>
      <div class="image-upload-link">
        <base-button type="button" class="btn btn-sm choose">
          <input
            :id="`imageInput-${componentId}`"
            :ref="`imageInput-${componentId}`"
            accept="image/*"
            type="file"
            @input="onFileChange"
          />
        </base-button>
      </div>
      <label v-if="!image" :for="`imageInput-${componentId}`" class="add-link"
        >Ajouter photo</label
      >
      <label v-else :for="`imageInput-${componentId}`"> </label>
      <button class="remove" v-if="image" type="button" @click="removeImage">
        <span class="icon">
          <i class="fal fa-trash-alt"></i>
        </span>
        <span class="text"> Supprimer l'image </span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-selector",

  props: {
    defaultImage: {
      type: String,
      default: null,
      description: "Default image to diplay, should be model value",
    },
    label: {
      type: String,
      default: null,
      description: "Label of the field",
    },
    ressource_name: {
      type: String,
      default: null,
      description:
        "Ressource name to be uploaded with, must be allowed in the api",
    },
    ressouce_id: {
      type: String,
      default: null,
      description:
        "Ressource id to be uploaded with, must be allowed in the api",
    },
    field: {
      type: String,
      default: null,
      description:
        "Ressource field to be uploaded with, must be allowed in the api",
    },
  },

  setup() {},

  data() {
    return {
      image: this.defaultImage,
      componentId: null,
    };
  },

  mounted() {
    this.componentId = this._uid;
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.uploadImage(files[0]);
      this.createImage(files[0]);
    },

    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async uploadImage(file) {
      if (file) {
        try {
          await this.$store.dispatch("files/upload", {
            image: file,
            ressource_name: this.ressource_name,
            ressource_id: this.ressource_id,
            field: this.field,
          });
          const image_url = await this.$store.getters["files/url"];
          this.$emit("imageChanged", image_url);
        } catch (error) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
          });
        }
      }
    },

    removeImage() {
      this.$refs[`imageInput-${this.componentId}`].value = null;
      this.image = null;
      this.$emit("imageChanged", null);
    },
  },

  watch: {
    defaultImage(defaultImage) {
      this.image = defaultImage;
    },
  },
};
</script>

<style lang="scss">
.image-upload-wrapper {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 10px auto 10px 0;

  .image-upload {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto 0 0;
    padding: 0;

    &:hover {
      button.remove {
        visibility: visible;
        opacity: 1;
      }
    }

    &-picture {
      width: 144px;
      height: 144px;
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 9;
      border-radius: 20px;
      background-color: rgba(174, 192, 130, 0.3);

      .profile-image {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: solid 1px #d6d6d6;

        &.default {
          position: relative;
          &:after {
            content: "";
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: rgba(174, 192, 130, 0.7);
            position: absolute;
            top: 53px;
            left: 45px;
          }
          img {
            width: 72px;
            height: 54px;
            border-radius: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          padding: 0;
          border-radius: 15px;
          object-fit: cover;
          object-position: center;
          border: none;
        }
      }
    }

    &-link {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      position: relative;
      top: 0;
      left: 0;
      z-index: 10;
      display: none;

      button {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: 50%;
        background-color: transparent;
        border: none;
        box-shadow: none;
        padding: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;

        &:hover {
          box-shadow: none;
          transform: none;
        }

        .choose {
          z-index: 1;
        }

        label {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          background-color: transparent;
          margin: 0;
          cursor: pointer;
        }

        input {
          display: none;
        }
      }
    }

    .add-link {
      font-size: 14px;
      line-height: 14px;
      margin: 10px 0 0 0;
      text-decoration: underline;
      font-family: "Greycliff CF Bold", sans-serif;
      color: #576654;
      cursor: pointer;
    }

    .remove {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: 3px 0 0 0;
      border: none;
      background-color: transparent;
      z-index: 2;
      span {
        display: flex;
        flex-direction: row;
        &.icon {
          width: 14px;
          height: 14px;
          i {
            font-size: 14px;
            color: rgb(255, 0, 0, 1);
          }
        }
        &.text {
          font-size: 14px;
          line-height: 14px;
          color: rgb(255, 0, 0, 1);
          margin: 0 0 0 5px;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
