<template>
  <div class="form-group taxes-selector">
    <label class="form-control-label"> {{ label }} </label>
    <div v-for="(tax, key) in taxesModel" :key="key" class="taxes-wrapper">
      <div class="form-group-item type">
        <label class="form-control-label"> {{ $t("COMMON.TYPE") }} </label>
        <el-select
          :label="$t('COMMON.TYPE')"
          :placeholder="$t('COMMON.TYPE')"
          v-model="tax.type"
          @change="taxesChanged"
        >
          <el-option
            :value="TAX_TYPE_PERCENTAGE"
            :label="$t('COMMON.TAX_TYPE_PERCENTAGE')"
          />
          <el-option
            :value="TAX_TYPE_AMOUNT"
            :label="$t('COMMON.TAX_TYPE_AMOUNT')"
          />
        </el-select>
      </div>
      <div class="form-group-item name">
        <base-input
          :label="$t('COMMON.NAME')"
          :placeholder="$t('COMMON.NAME')"
          v-model="tax.name"
          @change="taxesChanged"
        />
      </div>
      <div class="form-group-item rate">
        <base-input
          :label="$t('COMMON.RATE')"
          :placeholder="$t('COMMON.RATE')"
          v-model="tax.value"
          @change="taxesChanged"
        />
      </div>
      <div class="remove-taxes-button">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn btn-danger"
          @click.prevent="() => removeTax(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <base-button type="button" class="btn add" @click.prevent="addTax">
      <i class="far fa-plus"></i>
      <span>Ajouter</span>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { TAX_TYPE_PERCENTAGE, TAX_TYPE_AMOUNT } from "@/constants/taxes";

export default {
  name: "taxes-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
  },

  props: {
    taxes: {
      type: Array,
      default: null,
      description: "Taxes array",
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
  },

  data() {
    return {
      taxesModel: this.taxes,
      TAX_TYPE_PERCENTAGE: TAX_TYPE_PERCENTAGE,
      TAX_TYPE_AMOUNT: TAX_TYPE_AMOUNT,
    };
  },

  setup() {},

  created() {},

  methods: {
    addTax() {
      this.taxesModel.push({ name: null, value: 0, item: TAX_TYPE_PERCENTAGE });
      this.taxesChanged();
    },
    removeTax(key) {
      this.taxesModel = this.taxesModel.filter((_, idx) => idx !== key);
      this.taxesChanged();
    },
    taxesChanged() {
      const taxesData = this.taxesModel.map((item) => {
        const value = parseFloat(item.value);
        return {
          name: item.name,
          value: value ? value : null,
          type: item.type ? item.type : TAX_TYPE_PERCENTAGE,
        };
      });
      this.$emit("taxesChanged", taxesData);
    },
  },

  watch: {
    taxes(taxes) {
      this.taxesModel = cloneDeep(taxes);
    },
  },
};
</script>

<style lang="scss">
.form-group {
  &.taxes-selector {
    > label {
      display: block;
      font-size: 20px;
      margin-bottom: 24px;
      font-family: "Greycliff CF Medium", sans-serif;
      margin: 10px 0;
    }

    .form-group-item {
      flex: 1;
      padding: 0 10px;
      .el-select {
        margin-top: 8px;
      }
      &.remove {
        max-width: 53px;
      }
      .form-group {
        .valid-feedback {
          margin: 0;
        }
      }
    }

    .remove-taxes-button {
      padding-left: 10px;
      button {
        box-shadow: none;
        border: none;
        margin: 0;
        &.btn-danger {
          width: 47px;
          min-width: 47px;
          height: 47px;
          min-height: 47px;
          border-radius: 14px;
          background-color: #f5365c;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            font-size: 16px;
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }

    button {
      &.add {
        width: auto;
        height: 40px;
        border-radius: 8px;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;
        border: solid 1px #576654 !important;
        color: #576654 !important;
        i {
          font-size: 14px;
          color: #576654;
          margin: 0;
        }
        span {
          margin: 0 0 0 3px;
        }
      }
    }
  }

  .taxes-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 0 0 0;

    .form-group-item {
      &.type,
      &.rate {
        max-width: 190px;
      }
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
